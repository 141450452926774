exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-entwicklung-js": () => import("./../../../src/pages/android-entwicklung.js" /* webpackChunkName: "component---src-pages-android-entwicklung-js" */),
  "component---src-pages-app-design-js": () => import("./../../../src/pages/app-design.js" /* webpackChunkName: "component---src-pages-app-design-js" */),
  "component---src-pages-app-entwicklung-js": () => import("./../../../src/pages/app-entwicklung.js" /* webpackChunkName: "component---src-pages-app-entwicklung-js" */),
  "component---src-pages-app-marketing-js": () => import("./../../../src/pages/app-marketing.js" /* webpackChunkName: "component---src-pages-app-marketing-js" */),
  "component---src-pages-cross-plattform-entwicklung-js": () => import("./../../../src/pages/cross-plattform-entwicklung.js" /* webpackChunkName: "component---src-pages-cross-plattform-entwicklung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-entwicklung-js": () => import("./../../../src/pages/ios-entwicklung.js" /* webpackChunkName: "component---src-pages-ios-entwicklung-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-projektablauf-js": () => import("./../../../src/pages/projektablauf.js" /* webpackChunkName: "component---src-pages-projektablauf-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

